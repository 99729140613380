export const MenuItems = [
    {
        title: 'Delivery',
        // icon: Grubhub,
        path: 'https://www.ubereats.com/palm-springs/food-delivery/the-bunny-tea/q6gAeOFMR8iET6uAiUS2ig',
        cName: 'dropdown-link'
    },
    {
        title: 'Pick Up',
        // icon: Doordash,
        path: 'https://www.clover.com/online-ordering/the-bunny-tea-la-quinta',
        cName: 'dropdown-link'
    }
    
]